import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { BiCodeAlt } from "react-icons/bi";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaClipboardCheck } from "react-icons/fa";
import { RiGatsbyFill } from "react-icons/ri";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import Logos from "../../components/logos";
import OSSection from "../../components/other-services-section";
import OASection from "../../components/our-approach-section";

const SustainablePageDubai = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img1: wpMediaItem(title: { eq: "we-reccomend" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ellaImg: wpMediaItem(title: { eq: "Ellaroberta-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wantToImg: wpMediaItem(title: { eq: "want-to-create" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			greenHostingImg: wpMediaItem(title: { eq: "green-hosting" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			generatesImg: wpMediaItem(title: { eq: "generates" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "Sustainable Dubai" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Sustainable Web Design",
				item: {
					url: `${siteUrl}/dubai/sustainable-web-design`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/sustainable-web-design`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1="primary-link-dark"
						button1Text="Get in touch"
						button1Link="/dubai/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`Sustainable \nWeb Design \n<span class="text-secondary">DUBAI</span>`}
						description="More carbon emissions are produced by the internet than by the aviation industry. Could your website be more sustainable?"
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#accessible"
								>
									Accessible
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#sustainable"
								>
									Sustainable
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#profit"
								>
									Profitable
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7">
							<Col lg={6} className=" ">
								<h2 className="display-5 text-primary mb-5">
									Build a website that aligns with your net-zero goals
								</h2>
								<p>
									At RJM Digital, we understand the challenges businesses face
									in achieving their sustainability goals. With the upcoming
									COP28 hosted in Dubai, the focus on businesses to do
									everything they can will be greater than ever. We will help
									you create a new website or convert a pre-existing one into an
									eco-friendly, energy-efficient, low-carbon emitting website,
									whilst still reflecting your company brand and values. 
								</p>
							</Col>
							<Col lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.wantToImg.gatsbyImage}
										alt={data.wantToImg.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<Logos page="Sustainable" noTitle />
				<section id="accessible" className="bg-white py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6">
							<Col lg={6} className=" ">
								<p className="ssp-bold text-uppercase text-light-blue">
									ECO-FRIENDLY WEB ALLIANCE
								</p>
								<h2 className="display-5 text-primary mb-4">
									Make a positive impact with your website
								</h2>
								<p className="">
									RJM Digital are in partnership with the Eco-Friendly Web
									Alliance (EFWA) that are committed to helping to create a
									‘greener internet’.
								</p>
								<p>
									Find out the amount of carbon your current website produces
									with a free website emissions audit.
								</p>
								<Button
									className={`mt-4  fs-5 w-100 d-none d-md-inline-block w-md-auto cta-btn py-2 px-4 white-link-yellow `}
									variant="primary"
									as={Link}
									to="/website-emissions-audit"
								>
									Audit my site
								</Button>
							</Col>

							<Col className="mt-4 mt-lg-0" lg={{ span: 6, order: "first" }}>
								<div className="iframe-container-new">
									<iframe
										className="responsive-iframe"
										src="https://www.youtube.com/embed/GKrAMMBripc"
										title="Every single website has the power to make a difference"
										frameborder="0"
										allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
										loading="lazy"
									></iframe>
								</div>
								<Button
									className={`mt-5  fs-5 w-100  d-md-none w-md-auto cta-btn py-2 px-4 white-link-yellow `}
									variant="primary"
									as={Link}
									to="/website-emissions-audit"
								>
									Audit my site
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-primary py-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={6} className="text-center">
								<p className="ssp-bold text-uppercase text-light-blue">
									SUSTAINABLE WEB DESIGN
								</p>
								<h2 className="display-5 text-white mb-5 ">
									How we make our websites sustainable
								</h2>
							</Col>
						</Row>
						<Row className="my-5 gy-5">
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<BsFillLightningChargeFill className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									Green Energy
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									The energy used to power our data centres is 100% renewable.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<BiCodeAlt className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									Efficient Code
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									The majority of websites with a CMS have lots of unused code,
									we remove this to make it as lean as possible.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaClipboardCheck className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									Regular Auditing
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Once your energy-efficient website is built, our partnership
									with the eco-friendly web alliance will ensure it stays that
									way.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<RiGatsbyFill className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									Latest Technology
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									We reduce any image or video size by using the most up-to-date
									compression technologies.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button
									className={`mt-4 mt-md-5 fs-5 w-100  w-md-auto cta-btn py-2 px-4 primary-link-dark `}
									variant="white"
									as={Link}
									to="/dubai/contact-us"
								>
									Build my website
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section id="accessible" className="bg-primary py-5 py-lg-10">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={7} className=" ">
								<h2 className="display-4 text-white mb-5 mt-5 mt-lg-0">
									Accessible for all
								</h2>
								<p className="text-white">
									Your website needs to be accessible for any user, no matter
									how they access the internet. Our expert team can help you
									build your website so everyone can enjoy it, regardless of
									ability.
								</p>
							</Col>
							<Col className="mt-4 mt-lg-0" lg={{ span: 5, order: "first" }}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.img1.gatsbyImage}
										alt={data.img1.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7">
							<Col lg={6} className=" ">
								<p className="ssp-bold text-uppercase text-light-blue">
									GREEN WEBSITE HOSTING
								</p>
								<h2 className="display-5 text-primary mb-5">
									Host your website on renewable energy powered data centres
								</h2>
								<p>
									By using power from renewable sources, running your website on
									green hosting results in lowering your website's carbon
									emissions. At RJM Digital we host every website we build on
									eco-friendly data centres and provide you with reports so you
									can continually see the environmental impacts of your website.
								</p>
							</Col>
							<Col lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.greenHostingImg.gatsbyImage}
										alt={data.greenHostingImg?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				{/* <section id="profit" className="bg-primary py-5 py-lg-10">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={7} className=" ">
								<h2 className="display-4 text-white mb-5">Generates revenue</h2>
								<p className="text-white">
									In order to be sustainable, your business needs to be
									profitable. RJM can help you create a website that supports
									and sustains your business growth, generating more profit
									through higher conversions.
								</p>
							</Col>
							<Col lg={5}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={
											data.generatesImg.gatsbyImage
										}
										alt={data.generatesImg?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section> */}

				<div id="approach">
					<OASection
						dubai
						imgHeight="60rem"
						image={data.ellaImg.gatsbyImage}
						imageAlt={data.ellaImg?.altText}
						launch="Once the RJM team has thoroughly reviewed and tested your website for functionality and content, we launch it into the world. Our high-performance green servers ensure that your website is fast and reliable while also running on lower carbon emissions."
						build="We use various tools and procedures to ensure that your website is both eco-friendly and accessible. You can monitor the progress of your website on our test server, and we will ask for your approval on the final design before we launch, ensuring that you are completely happy with the end result."
						design="After discussing your needs, RJM Digital will begin the website construction process. At this stage, you can suggest as many modifications as necessary until you are 100% happy. Our team will ensure that your website is user-friendly, eco-friendly, and optimised for display on any device your customers use."
						brief="Our team of experts will collaborate with you throughout the entire process to ensure that your website looks appealing, is fully functional, accessible, and sustainable. The final outcome will be a website that reflects your business and brand, and works as a critical component of your marketing strategy."
					/>
				</div>
				<section className="pt-5 pt-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="display-5 text-primary mb-3">
									Book a discovery meeting
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your project.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Sustainable" />
				</section> */}
				<Faq page="Sustainable" />
				<OSSection
					dubai
					link3="/branding"
					text3="BRANDING"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/>
			</Layout>
		</>
	);
};

export default SustainablePageDubai;
